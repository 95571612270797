import React from 'react';
import { Helmet } from 'react-helmet';
import { AppBar, Toolbar, Typography, Container, Box, Paper, List, ListItem, ListItemText } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import WhyHumanConnectionSection from './components/WhyHumanConnectionSection';
import ParallaxBanner from './components/ParallaxBanner';
import bannerImage from './images/banner.webp';

// Define a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    h1: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      color: '#2c3e50',
      textAlign: 'center',
      marginBottom: '20px',
    },
    h2: {
      fontSize: '1.5rem',
      color: '#1976d2',
      marginBottom: '10px',
    },
    body1: {
      fontSize: '1rem',
      color: '#4a4a4a',
    },
  },
});

function App() {
  const pageDescription = "Human Name System (HNS) - A decentralized identity verification system that maps human names to verified digital profiles, creating a bot-free social network with enhanced privacy and security.";
  const keywords = "Human Name System, HNS, digital identity, blockchain identity, verified profiles, decentralized identity, bot-free social network, identity verification";
  const baseUrl = "https://imhmn.org";
  
  // Try both approaches for the preview image URL
  const previewImageUrl = process.env.NODE_ENV === 'production'
    ? window.location.origin + require('./images/banner.webp')
    : new URL(bannerImage, baseUrl).href;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      
      {/* Enhanced Meta Tags */}
      <Helmet>
        <title>Human Name System (HNS) - Decentralized Identity Verification Platform</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={keywords} />
        
        {/* Facebook Open Graph Tags */}
        <meta property="og:title" content="Human Name System (HNS) - Decentralized Identity Verification Platform" />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={previewImageUrl} />
        <meta property="og:image:alt" content="Real People, Real Conversations - Human Name System" />
        <meta property="og:image:type" content="image/webp" />
        <meta property="og:url" content={baseUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Human Name System" />
        <meta property="og:locale" content="en_US" />

        {/* LinkedIn Specific Tags */}
        <meta property="linkedin:owner" content="Human Name System" />
        <meta name="author" content="Human Name System" />
        
        {/* Basic SEO and Favicon Tags */}
        <link rel="canonical" href={baseUrl} />
        <link rel="icon" type="image/png" href="/favicon-96x96.png" sizes="96x96" />
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <meta name="apple-mobile-web-app-title" content="imhmn" />
        <link rel="manifest" href="/site.webmanifest" />
        <html lang="en" />
      </Helmet>

      {/* Semantic Header */}
      <header>
        <AppBar position="static" color="primary" component="nav">
          <Toolbar>
            <Typography variant="h6" component="h1" sx={{ flexGrow: 1 }}>
              Human Name System (HNS) Concept
            </Typography>
          </Toolbar>
        </AppBar>
      </header>
      
      <ParallaxBanner />
      
      {/* Main Content */}
      <main>
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
          <WhyHumanConnectionSection />
          <article>
            <Typography variant="h1" component="h1" aria-label="Human Name System Concept Overview">
              Human Name System (HNS) Concept
            </Typography>
            
            <section aria-label="Open Source Repository">
              <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
                <Typography variant="h2" component="h2">Open Source Repository</Typography>
                <Typography variant="body1" component="p">
                  A project this ambitious requires resources. Please check out our{' '}
                  <a href="https://github.com/stephen-tenorless/HNS" title="HNS GitHub Repository" aria-label="Visit HNS GitHub Repository">
                    GitHub repository
                  </a>
                  . Please consider sponsoring the repository - every contribution helps. Our goal is to promote civil discourse and accountability in online discussions.
                </Typography>
                
                <iframe 
                  src="https://github.com/sponsors/stephen-tenorless/card" 
                  title="Sponsor stephen-tenorless" 
                  height="225" 
                  width="600" 
                  style={{ border: 0 }} 
                ></iframe>
              </Paper>
            </section>

            <section aria-label="Identity Mapping Details">
              <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
                <Typography variant="h2" component="h2">Identity Mapping in HNS</Typography>
                <Typography variant="body1" component="p">
                  HNS creates a secure bridge between real-world identities and digital profiles, similar to DNS mapping domain names to IP addresses. Each profile contains essential verified information and cryptographic proof of identity, ensuring authenticity while maintaining privacy.
                </Typography>
              </Paper>
            </section>

            <section aria-label="Identity Verification and Privacy">
              <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
                <Typography variant="h2" component="h2">Identity Verification and Privacy</Typography>
                <Typography variant="body1" component="p">
                  Our system implements robust identity verification through government IDs and trusted third-party verifiers. Users maintain complete control over their public information, striking the perfect balance between privacy and authenticity.
                </Typography>
              </Paper>
            </section>

            <section aria-label="Bot-Free Network">
              <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
                <Typography variant="h2" component="h2">Bot-Free Social Network</Typography>
                <Typography variant="body1" component="p">
                  Experience genuine human interaction in our social network where every participant is verified. Our strict verification process eliminates bots and fake accounts, fostering authentic connections and meaningful discussions.
                </Typography>
              </Paper>
            </section>

            <section aria-label="Network Features">
              <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
                <Typography variant="h2" component="h2">Features of the Network</Typography>
                <List component="ul" aria-label="Network features list">
                  <ListItem><ListItemText primary="Decentralized Trust: No central platform controls user identity" /></ListItem>
                  <ListItem><ListItemText primary="Selective Sharing: Users can share information only with trusted circles" /></ListItem>
                  <ListItem><ListItemText primary="Spam and Abuse Prevention: Verified identities reduce spam and enhance accountability" /></ListItem>
                  <ListItem><ListItemText primary="Portable Profiles: Users can move between networks with their reputation intact" /></ListItem>
                </List>
              </Paper>
            </section>

            <section aria-label="Final Thoughts">
              <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="h2" component="h2">Final Thoughts</Typography>
                <Typography variant="body1" component="p">
                  The Human Name System represents a fundamental shift in online identity management, offering a solution to the challenges of digital authenticity and trust. By providing verified, portable identities, HNS empowers users while fostering meaningful online interactions.
                </Typography>
                <Typography variant="body1" component="p">
                  We welcome your thoughts on this initiative. Would you value a verified, portable digital identity? How do you view the balance between privacy and accountability in online spaces?
                </Typography>
              </Paper>
            </section>
          </article>
        </Container>
      </main>

      {/* Footer */}
      <footer>
        <Box component="footer" sx={{ py: 3, px: 2, mt: 'auto', backgroundColor: theme.palette.primary.main }}>
          <Container maxWidth="sm">
            <Typography variant="body2" color="white" align="center">
              © {new Date().getFullYear()} Human Name System. All rights reserved.
            </Typography>
          </Container>
        </Box>
      </footer>
    </ThemeProvider>
  );
}

export default App;