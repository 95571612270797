import React from 'react';
import { Paper, Typography, Box, Grid } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SecurityIcon from '@mui/icons-material/Security';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import GroupsIcon from '@mui/icons-material/Groups';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

const WhyHumanConnectionSection = () => {
  return (
    <Box sx={{ my: 6 }}>
      <Typography 
        variant="h2" 
        component="h2" 
        sx={{ 
          fontSize: '2rem', 
          fontWeight: 'bold', 
          textAlign: 'center', 
          mb: 4 
        }}
      >
        Why Real Human Connection Matters
      </Typography>
      
      <Grid container spacing={4}>
        {/* The Human Experience Card */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4, height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <FavoriteIcon sx={{ color: '#1976d2', mr: 2 }} />
              <Typography variant="h3" sx={{ fontSize: '1.5rem', fontWeight: 600 }}>
                The Power of Human Understanding
              </Typography>
            </Box>
            <Typography sx={{ color: 'text.secondary' }}>
              Remember the last time someone truly understood how you felt? That's something only real human interaction can provide. When we communicate with actual people, we share experiences, emotions, and perspectives that shape our understanding of the world. Unlike automated responses, human conversations adapt, show empathy, and create meaningful connections that help us grow both personally and professionally.
            </Typography>
          </Paper>
        </Grid>

        {/* Trust & Safety Card */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4, height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <SecurityIcon sx={{ color: '#1976d2', mr: 2 }} />
              <Typography variant="h3" sx={{ fontSize: '1.5rem', fontWeight: 600 }}>
                Building Trust in Digital Spaces
              </Typography>
            </Box>
            <Typography sx={{ color: 'text.secondary' }}>
              Just as you trust the person you're having coffee with, online conversations should come with the same level of authenticity. When you know you're talking to a real person who's accountable for their words, discussions become more meaningful, respectful, and productive. This trust creates a foundation for genuine dialogue and community building.
            </Typography>
          </Paper>
        </Grid>

        {/* The Bot Problem Card */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4, height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <SmartToyIcon sx={{ color: '#1976d2', mr: 2 }} />
              <Typography variant="h3" sx={{ fontSize: '1.5rem', fontWeight: 600 }}>
                Why Bots Are Breaking Our Conversations
              </Typography>
            </Box>
            <Typography sx={{ color: 'text.secondary' }}>
              Think about trying to have a serious conversation in a room full of loudspeakers playing random messages. That's what social media feels like today. Bots flood our spaces with automated messages, fake engagement, and artificial opinions. They drown out real voices, manipulate discussions, and make it hard to know what's genuine. This noise doesn't just annoy us – it fundamentally breaks down our ability to connect and understand each other.
            </Typography>
          </Paper>
        </Grid>

        {/* Real Impact Card */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4, height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <GroupsIcon sx={{ color: '#1976d2', mr: 2 }} />
              <Typography variant="h3" sx={{ fontSize: '1.5rem', fontWeight: 600 }}>
                The Real Impact on Our Society
              </Typography>
            </Box>
            <Typography sx={{ color: 'text.secondary' }}>
              When we can't trust who we're talking to online, it affects more than just our social media experience. It impacts how we form opinions, make decisions, and understand current events. Bot-driven misinformation and artificial engagement can shape public opinion, influence elections, and even affect mental health. By ensuring real human-to-human connection, we can rebuild the trust and authenticity that make meaningful dialogue possible.
            </Typography>
          </Paper>
        </Grid>

        {/* Solution Card */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <VerifiedUserIcon sx={{ color: '#1976d2', mr: 2 }} />
              <Typography variant="h3" sx={{ fontSize: '1.5rem', fontWeight: 600 }}>
                A Return to Authentic Connection
              </Typography>
            </Box>
            <Typography sx={{ color: 'text.secondary', mb: 2 }}>
              Imagine social platforms where every conversation is with a real person – where discussions are genuine, responses are thoughtful, and communities are built on trust rather than algorithms. This isn't just about removing bots; it's about creating spaces where authentic human connection can flourish.
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              The Human Name System (HNS) aims to make this vision a reality by ensuring that behind every profile is a real person, accountable for their words and actions. This creates an environment where meaningful discussions can happen, where trust can be built, and where genuine human connection can thrive in the digital age.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhyHumanConnectionSection;