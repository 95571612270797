import React from 'react';
import { Box, Typography } from '@mui/material';
import bannerImage from '../images/banner.webp'; // If image is in src/images
// OR use process.env.PUBLIC_URL if image is in public/images

const ParallaxBanner = () => {
  // Use either the imported image or PUBLIC_URL depending on image location
  const backgroundImageUrl = bannerImage || `${process.env.PUBLIC_URL}/images/banner.webp`;
  
  return (
    <Box
      sx={{
        position: 'relative',
        height: '80vh',
        width: '100%',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          filter: 'brightness(0.85)',
          zIndex: -1,
        }
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          textAlign: 'center',
          padding: '0 20px',
          background: 'linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3))',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: '2rem', md: '3.5rem' },
            fontWeight: 'bold',
            marginBottom: '1rem',
            color: 'white',
            textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
          }}
        >
          Real People, Real Conversations
        </Typography>
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.2rem', md: '1.8rem' },
            maxWidth: '800px',
            color: 'white',
            textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
          }}
        >
          Building a verified digital community where authentic human connections thrive
        </Typography>
      </Box>
    </Box>
  );
};

export default ParallaxBanner;